import { Container, Grid, GridItem } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";
import Head from "next/head";
import { Adsense } from "ui/src/components/AdSense/Adsense";
import { MainHero } from "ui/src/components/HomeDomain";
import { Layout } from "ui/src/components/HomeDomain/Layout";
import { Newsletters } from "ui/src/components/HomeDomain/Newsletters";

const Main = () => {
  return (
    <>
      <MainHero byline="Weekly recaps, newsletters and submissions for the dev community ❤️" />
      <Container maxW="5xl">
        <Newsletters />
      </Container>
    </>
  );
};

const Desktop = () => {
  return (
    <Grid templateColumns="repeat(5, 1fr)" gap={6}>
      <GridItem colSpan={1}>{/* <Adsense /> */}</GridItem>
      <GridItem colSpan={3}>
        <Main />
      </GridItem>
      <GridItem colSpan={1}>{/* <Adsense /> */}</GridItem>
    </Grid>
  );
};

const Mobile = () => {
  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/fonts/Virgil.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <meta
          name="google-site-verification"
          content="rF9T3lHvmMrLF2AA1DpzkyZUJ6MZCKYvXAtxpuRyhL8"
        />
        <title>{`TheLastWeekIn.Dev`}</title>
        <meta
          name="description"
          content={`Weekly dev newsletters for the community.`}
        />
        <meta property="og:title" content={`TheLastWeekIn.Dev`} key="title" />
        <meta
          property="og:url"
          content={`https://www.thelastweekin.dev`}
          key="url"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={`Weekly dev newsletters for the community.`}
        />
        <meta property="og:image" content="/assets/tlwi-og.png" />
        <meta name="twitter:title" content={`TheLastWeekIn.Dev`} />
        <meta
          property="twitter:description"
          content={`Weekly dev newsletters for the community.`}
        />
        <meta
          property="twitter:url"
          content={`https://www.thelastweekin.dev`}
          key="twitter-url"
        />
        <meta property="twitter:image" content="/assets/tlwi-og.png" />
      </Head>
      <Main />
      <Adsense />
    </>
  );
};

const IndexPage = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Layout title="Home | TheLastWeekIn.Dev">
      {isMobile ? <Mobile /> : <Desktop />}
    </Layout>
  );
};

export default IndexPage;
